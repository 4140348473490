.modal {
  position: fixed; /* Ensure the modal is fixed to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* High z-index to ensure it appears above other content */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  height: 90vh; /* Ensure it takes 90% of the viewport height */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  overflow: hidden; /* Prevent horizontal scrolling */
}

.codemirror-container {
  flex-grow: 1;
  height: 80%; /* Make the CodeMirror container take 80% of the modal content height */
  overflow-y: auto; /* Allow vertical scroll */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.CodeMirror {
min-height: 100vh;
  height: 100%; /* Ensure CodeMirror editor takes up full height of its container */
  width: 100%; /* Ensure the CodeMirror editor takes up the full width */
  box-sizing: border-box; /* Ensure padding and border don't affect height */
  overflow-x: hidden;
}

.CodeMirror-scroll {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}
