@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  font-family: "Poppins", "sans-serif";
}

.error-select .select__control {
  @apply border-red-600;
}

.copied {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.2s ease-in;
}

.copied::after {
  content: "";
  position: absolute;
  right: -5px;
  top: 6px;
  background: black;
  -webkit-clip-path: polygon(50% 0, 50% 100%, 100% 50%);
  clip-path: polygon(50% 0, 50% 100%, 100% 50%);
  width: 11px;
  height: 11px;
}

.copy-show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.svg-size svg {
  width: 40px;
  height: 40px;
}

.svg-mainsize svg {
  width: 65px;
  height: 65px;
}

.svg-taskview svg {
  width: 100px;
  height: 100px;
}

.nepali-num,
._1oYOS {
  font-family: "Noto Sans Devanagari", Arial, sans-serif;
}

.calendar-font {
  font-size: 13px !important;
}

._1hh2I._3Rbbx span {
  font-family: "Noto Sans Devanagari", Arial, sans-serif !important;
}

._2aNF9 option,
._2aNF9 {
  font-family: "Noto Sans Devanagari", Arial, sans-serif !important;
}

/* Custom Percentage  */

/* // Custom progress */

@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div.custom-progress-circle {
  --size: 10rem;
  --fg: #b83955;
  --bg: #b8395542;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
}

/* div[role="progressbar"]::before {
      counter-reset: percentage var(--value);
      content: counter(percentage) '%';
    } */

/* Fixed Header Table  */
.primary {
  overflow: auto;
  scroll-snap-type: both mandatory;
  height: 50vh;
}
@media (min-width: 40em) {
  .primary {
    order: 2;
  }
}

.custom-table table {
  border-collapse: collapse;
  border: 0;
}
.custom-table th,
.custom-table td {
  border: 1px solid #aaa;
  background-clip: padding-box;
  scroll-snap-align: start;
}
.custom-table tbody tr:last-child th,
.custom-table tbody tr:last-child td {
  border-bottom: 0;
}
.custom-table thead {
  z-index: 1000;
  position: relative;
}
.custom-table th,
.custom-table td {
  padding: 0.6rem;
  min-width: 6rem;
  text-align: left;
  margin: 0;
}
.custom-table thead th {
  position: sticky;
  top: 0;
  border-top: 0;
  background-clip: padding-box;
}
.custom-table thead th.pin {
  left: 0;
  z-index: 1001;
  border-left: 0;
}
.custom-table tbody th {
  background-clip: padding-box;
  border-left: 0;
}
.custom-table tbody {
  z-index: 10;
  position: relative;
}
.custom-table tbody th {
  position: sticky;
  left: 0;
}
.custom-table thead th,
.custom-table tbody th {
  background-color: #f8f8f8;
}
/* Add this to your CSS file or use Tailwind classes directly */
.tab-link {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}

.tab-link:hover {
  color: #374151;
  border-bottom-color: #3b82f6;
}

.tab-link.active {
  color: #3b82f6;
  border-bottom-color: #3b82f6;
}
