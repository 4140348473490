body{
  background-color: #f5f5f5;
  min-height: 100vh;
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #06b975;
}

.ql-editor {
  height: 250px !important;
  max-height: 250px;
  overflow: auto;
}

textarea {
  resize: none;
}
